import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import verify from '../views/verify.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.substring(to.path.length - 4) === '.txt' && to.name === 'verify') {
    next()
  } else if (to.name === 'Home') {
    next()
  } else {
    next('/')
  }
})

export default router
