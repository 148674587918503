<template>
  <div class="home">
    <div class="banner">
      <div class="head">
        <div class="head-container">
          <div class="header_left">
            <img v-if="list.logo" class="header_left_img" :src="list.logo" alt="">
          </div>
          <div class="tel">电话：{{ list.phone }}</div>
        </div>
      </div>
      <div class="bg"></div>
      <img src="@/assets/banner.png" alt="">
    </div>
    <div class="content">
      <div class="section"><img src="@/assets/section1.png" alt=""></div>
      <div class="section"><img src="@/assets/section2.png" alt=""></div>
      <div class="section"><img src="@/assets/section3.png" alt=""></div>
      <div class="section"><img src="@/assets/section4.png" alt=""></div>
      <div class="section"><img src="@/assets/section5.png" alt=""></div>
      <div class="section"><img src="@/assets/section6.png" alt=""></div>
      <div class="section"><img src="@/assets/section7.png" alt=""></div>
      <div class="section"><img src="@/assets/section8.png" alt=""></div>
      <div class="section"><img src="@/assets/section9.png" alt=""></div>
      <div class="section"><img src="@/assets/section10.png" alt=""></div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-left">
          <div class="tel-box">
            <div class="img-box"><img src="@/assets/tel.png" alt=""></div>
            <div class="text-box">
              <p>咨询热线</p>
              <p class="tel">{{ list.phone }}</p>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <p class="company-name">{{ list.title }}</p>
          <p class="address">{{ list.address }}</p>
          <p>
            <a v-if="list.icp_number" href="https://beian.miit.gov.cn/#/Integrated/index" id="server_name">{{list.icp_number}}</a>
            <a v-if="list.filing_no" target="_blank" :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${list.filing_no}`" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"> | <img src="" style="float:left;"/><img style="margin-right: 5px;vertical-align:bottom" src="../assets/备案图标.png" alt="">{{list.filing_no}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDepressed } from '@/api/record.js'
export default {
  name: 'Home',
  data () {
    return {
      list: {}
    }
  },
  created () {
    this.getDepressed()
  },
  methods: {
    async getDepressed () {
      const protocol = window.location.protocol;
      const domain = window.location.hostname;
      const { data: res } = await getDepressed({
        domain: protocol + '//' + domain
      })
      document.title = res.data.title
      this.list = res.data || {}
    }
  }
}
</script>

<style scoped>
  .banner {
    position: relative;
    height: 1100px;
    background: #f9faff;
  }
  .head {
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .head-container {
    width: 1200px;
    height: 68px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .head-container .header_left_img {
    height: 38px;
  }
  .head-container .tel {
    font-size: 20px;
    color: #fff;
  }
  .banner .bg {
    height: 500px;
    background: linear-gradient(to bottom, #4591ff, #004be1)
  }
  .banner>img {
    width: 1100px;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .section {
    padding: 100px 0;
    text-align: center;
  }
  .section img {
    width: 1100px;
  }
  .footer {
    width: 100%;
    height: 446px;
    background: #071829;
  }
  .footer-content {
    width: 1200px;
    height: 446px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tel-box {
    display: flex;
    align-items: center;
  }
  .tel-box img {
    width: 60px;
  }
  .tel-box .text-box {
    margin-left: 20px;
    font-size: 16px;
    line-break: 20px;
    color: #fff;
    text-align: left;
  }
  .tel-box .text-box .tel {
    font-size: 28px;
    line-height: 22px;
    margin-top: 10px;
    font-weight: bold;
  }
  .code-box {
    margin-top: 36px;
  }
  .code-box img {
    width: 150px;
  }
  .code-box p {
    font-size: 14px;
    color: #ccc;
    margin-top: 10px;
    text-align: center;
  }
  .code-box .qrcode {
    margin-left: 38px;
  }
  .footer-right {
    padding-top: 24px;
    color: #6b7c8c;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    margin-left: 100px;
  }
  .footer-right .company-name {
    font-size: 18px;
    font-weight: 500;
  }
  .footer-right a {
    color: #6b7c8c;
    text-decoration: none;
  }
  .clearfix::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    line-height: 0;
    clear: both;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
</style>