<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  if (window.location.hostname.includes('morimori.com.cn')) {
    window.location.href = 'https://' + window.location.hostname + '/h5/pages/index/index?shop_id=479656385114673152'
  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  color: #2c3e50;
}
</style>
