<template>
  <div style="text-align: left">{{ text }}</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      text: ''
    }
  },
  mounted() {
    const document_name = this.$route.params.id
    axios({
      method: 'get',
      url: 'https://img.qbyun.com/uploads/txt/' + document_name,
    }).then(res => {
      this.text = res.data
      document.querySelectorAll('*').forEach(element => {
        element.remove()
      })
      
    })
  }
}
</script>

<style>

</style>